.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.segmented-disabled-item,
.segmented-disabled-item:hover,
.segmented-disabled-item:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}

.segmented-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.ant-segmented {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.66667;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  padding: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-segmented-group {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  width: 100%;
}

.ant-segmented.ant-segmented-block {
  display: flex;
}

.ant-segmented.ant-segmented-block .ant-segmented-item {
  flex: 1;
  min-width: 0;
}

.ant-segmented:not(.ant-segmented-disabled):hover,
.ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: rgba(0, 0, 0, 0.06);
}

.ant-segmented-item {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  color: #262626;
}

.ant-segmented-item:hover,
.ant-segmented-item:focus {
  color: #262626;
}

.ant-segmented-item-label {
  min-height: 24px;
  padding: 0 7px;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.ant-segmented-item-icon+* {
  margin-left: 8px / 2;
}

.ant-segmented-item-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 28px;
  padding: 0 11px;
  font-size: 14px;
  line-height: 28px;
}

.ant-segmented.ant-segmented-sm .ant-segmented-item-label {
  min-height: 18px;
  padding: 0 7px;
  line-height: 18px;
}

.ant-segmented-item-disabled,
.ant-segmented-item-disabled:hover,
.ant-segmented-item-disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-segmented-thumb {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  padding: 4px 0;
}

.ant-segmented-thumb-motion-appear-active {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, width;
}

.ant-segmented.ant-segmented-rtl {
  direction: rtl;
}

.ant-segmented.ant-segmented-rtl .ant-segmented-item-icon {
  margin-right: 0;
  margin-left: 6px;
}

.ant-form-item-explain {
  position: absolute;
  bottom: -25px;
  font-size: small;
  color: #fb7c7c;
  padding-left: 10px;
}

.small-button {

  width: 30px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-radius: 4px !important;
  text-align: center !important;

}

.small-button-bg-primary {

  background-color: rgba(62, 121, 247, 0.1);
  border: 1px solid rgb(230, 235, 241);
  box-shadow: rgb(0 0 0 / 2%) 0px 2px 0px;
}

.small-button-bg-default {


  border: 1px solid rgb(230, 235, 241);
  box-shadow: rgb(0 0 0 / 2%) 0px 2px 0px;
}




.bg-gray-min {
  background-color: #FAFBFC;
}

.color-orange {
  color: #d46b08
}

.color-green {
  color: #389e0d
}

.color-blue {
  color: #03a9f4
}



.bg-gray-min {
  background-color: #FAFBFC;
}

.contact-table {
  color: rgb(129 142 151);
  font-family: 'Gibson', sans-serif !important;
}

.contact-table th {
  background-color: #FAFBFC !important;
}

.contact-table th:first-child {
  background-color: #FAFBFC !important;
}

.contact-table .ant-table-content {
  background-color: #FAFBFC;
}

.contact-table .ant-table-cell-row-hover {
  border-bottom: 1px solid rgb(230, 235, 241) !important;
  cursor: pointer;
}

.contact-table .ant-table-cell {
  background: #ffffff
}

.contact-table th,
.contact-table td {
  text-align: left;
  font-weight: normal !important;
  color: rgba(82, 99, 115, 0.5) !important;
}

.contact-table tr {
  background-color: #ffffff;
}

.contact-table .ant-table-tbody>tr>td {
  padding-bottom: 1px solid;
  border-bottom: 1px solid #fff;
}

.contact-table tr:hover {
  border-bottom: 1px solid rgb(230, 235, 241);
}

.contact-table td:first-child {
  box-shadow: -20px 0 20px -4px rgba(0, 0, 0, 0.02);
}




/*
.contact-table td:last-child ,.contact-table th:last-child {
  width: 50px;

}*/



.contact-table thead {
  display: table;
  table-layout: fixed;
  width: calc(100%);
}

.contact-table tbody {
  display: table;
  table-layout: fixed;
  width: calc(100%);
  border-collapse: separate;
  border-spacing: 0 5px;
}

.contact-table tr.ant-table-expanded-row-level-1 {
  table-layout: fixed;
  width: calc(100%);
  border-collapse: separate;
  position: relative;

  height: calc(100% + 16px) !important;
}

.contact-table tr.ant-table-expanded-row-level-1>td {
  border-top: 1px solid rgb(230, 235, 241);
}



.contact-table .card-crawler-summary {
  border-spacing: 0px 0px;
}

.small-button {
  width: "30px" !important;
  text-align: center;
}

.icon-size-sm {
  font-size: 1.3rem;
  font-weight: bolder
}

.contact-table .ant-card-head {
  background-color: #f4f5f7;
  font-size: 14px;
  color: #526373;
  font-weight: normal;
  padding: 5px 5px 5px 20px;
}

.contact-table .ant-card-head-title {
  padding-top: 0px;
}

.contact-table .actions-column {
  width: 60px
}

.contact-table .name-column {
  min-width: 200px
}

.contact-table .usage-column {
  width: 100px
}

.contact-table .lastrun-column {
  width: 120px;
  font-size: 12px;
}

.contact-table th:nth-child(2) {
  min-width: 200px;
}

.contact-table .name-column {
  width: 100px;
}

@media (min-width:768px) {
  .contact-table td:not(:nth-child(1)) {
    box-shadow: 12px 0 12px 2px rgba(0, 0, 0, .02);
  }

  .contact-table .name-column {
    width: 200px;
  }
}

@media (min-width:992px) {
  .contact-table .usage-column {
    width: auto
  }

  .contact-table .lastrun-column {
    width: auto
  }

  .contact-table .name-column {
    width: 350px;
  }

}

@media (min-width:1200px) {
  .contact-table .name-column {
    width: 400px;
  }

}