.header-divider{
    border-bottom:1px solid rgba(82,99,115,0.1);
    border-top:1px solid rgba(82,99,115,0.1);

}
.bg-gray-min{
    background-color: #FAFBFC;
}
#edit-crawler-container{
   color: rgb(129 142 151);
   font-family: 'Gibson',sans-serif !important;


}

.card-shadow{
    /*box-shadow: 12px 0 12px 2px rgba(0,0,0,.02);*/
    box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.03);
    margin-left:5px
}

.pt-25{
    padding-top: 12px;
}

.account-list-table .status-column{
    width:90px
  }

  .account-list-table .ant-table-cell-row-hover {
    border-bottom: 1px solid rgb(230, 235, 241) !important;
    cursor: pointer;
}